import React, { FC } from "react"
import Head from "~/components/Head"
import Layout from "~/components/Layout"
import ErrorTemplate from "~/components/ErrorTemplate"

const errorDetail = {
  status: 500,
  message: "Internal Server Error",
  desc: [
    "ページを表示できませんでした。",
    "不具合が発生している可能性があります。",
  ],
}

const ServerErrorPage: FC = () => (
  <>
    <Head title={errorDetail.status.toString()} />
    <Layout>
      <ErrorTemplate
        status={errorDetail.status}
        message={errorDetail.message}
        textList={errorDetail.desc}
      />
    </Layout>
  </>
)

export default ServerErrorPage
